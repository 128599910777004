<template>
    <div v-loading="loading" class="panel">
        <div class="header-panel">
            <div class="col-xl-4 col-lg-3 col-md-12 col-sm-12 col-12 text-xl-left text-lg-left text-md-center text-sm-center text-center px-0">
                <p class="header-panel__titulo text-general f-500 f-18 tres-puntos"> {{ pedidoSeleccionado.nombre ? pedidoSeleccionado.nombre: '' }} </p>
            </div>
            <div class="col-xl-auto col-lg col-md-12 col-sm-12 col-12 px-0">
                <div class="header-panel__options justify-content-xl-start justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center">
                    <el-tooltip effect="light" content="Editar pedido" placement="bottom">
                        <i class="icon-pencil-outline f-25 cr-pointer" @click="editarPedidosRecurrente" />
                    </el-tooltip>
                    <el-tooltip effect="light" content="Eliminar pedido" placement="bottom">
                        <i class="icon-trash-empty f-25 ml-3 cr-pointer" @click="$refs.eliminarPedidoRecurrente.toggle()" />
                    </el-tooltip>
                    <el-tooltip effect="light" content="Añadir pedido" placement="bottom">
                        <button type="button" class="header-panel__btn header-panel__btn--position ml-3 f-25 bg-general2 text-white" @click="$emit('toggle')">
                            +
                        </button>
                    </el-tooltip>
                    <el-tooltip effect="light" content="Añadir a carrito" placement="bottom">
                        <button type="button" class="header-panel__btn ml-2 bg-general2 p-0">
                            <i class="icon-cart-plus text-white f-23" @click="$refs.modalAnadir.toggle()" />
                        </button>
                    </el-tooltip>
                    <button type="button" class="header-panel__btn header-panel__btn--descuento ml-2 bg-leeche text-white" @click="openModalRealizarPedido">
                        Realizar pedido
                    </button>
                </div>
            </div>
        </div>
        <div class="list-productos">
            <div class="list-productos__cantidades px-3 py-3">
                <p class="list-productos__vtotal text-general f-600 mr-3">{{ pedidoSeleccionado.subtotal ? `${separadorNumero (pedidoSeleccionado.subtotal)}`: '' }}</p>
                <p class="list-productos__und text-general f-600">{{ pedidoSeleccionado.productos_count }} <span class="ml-1">Productos</span></p>
            </div>
            <div class="list-productos__layout custom-scroll">
                <card-producto v-for="(producto, index) in productos" ref="cardProducto" :key="index" :producto="producto" :pedido-activo="pedidoActivo" @agregarProducto="agregarProducto2" @eliminarProducto="eliminarProducto" />
            </div>
        </div>
        <modalSiNo
        ref="modalAnadir"
        titulo="Añadir al Carrito"
        mensaje="¿Desea Añadir los Productos al Carrito de Abastecimiento?"
        icon="Alistar"
        @accion="agregarAcarritoTendero"
        />
        <modal-pedido-almacen ref="modalPedidoAlmacen" />
        <modal-eliminar-pedido-recurrente ref="eliminarPedidoRecurrente" titulo="Eliminar pedido recurrente" mensaje="¿Estas seguro de eliminar este pedido?" @eliminar="eliminarRecurrente" />
    </div>
</template>


<script>
import PedidosRecurrentes from '~/services/almacen/pedidos_recurrentes'
import Almacen from "~/services/almacen/almacen"
export default {
    components:{
        cardProducto: ()=> import('./componentes/cardProducto'),
        modalPedidoAlmacen: () => import('~/pages/pedidos/partials/modalRealizarPedidoAbast'),
        modalEliminarPedidoRecurrente: () => import('../../../../components/modal/eliminar')
    },
    props:{
        pedidoActivo:{
            type: Number,
            default: 0
        }
    },
    data(){
        return{
            num: 0,
            loading: false,
            pedidoSeleccionado: {
                nombre: '',
                subtotal: 0,
                productos_count: 0,
            },
            productos: [],
        }
    },
    watch: {
        pedidoActivo(){
            this.listarProductosRecurrentes()
        }
    },
    mounted(){
        this.listarProductosRecurrentes()
    },
    methods:{
        async listarProductosRecurrentes(){
            try {
                this.loading = true
                const { data } = await PedidosRecurrentes.listarProductosRecurrentes(this.pedidoActivo)
                this.pedidoSeleccionado = data.recurrente
                this.productos = data.productos

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        editarPedidosRecurrente(){
            let titulo = 'Editar Pedido recurrente'
            this.$parent.crearPedidoRecurrente(titulo,this.pedidoSeleccionado)
        },
        eliminarPedidoRecurrente(){
            this.$refs.eliminarPedidoRecurrente.toggle()
        },
        async eliminarRecurrente(){
            try {
                const { data } = await PedidosRecurrentes.eliminarRecurrente(this.pedidoActivo)
                this.$emit('eliminar', this.pedidoActivo)
                this.notificacion('Mensaje', data.message, 'success')
                this.$refs.eliminarPedidoRecurrente.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        eliminarProducto(val){
            console.log(val,'panel');

            let i = this.productos.findIndex(el => el.id_recurrente_producto == val.id_recurrente_producto)
            this.productos.splice(i,1)
            this.pedidoSeleccionado = val.recurrente
            this.$emit('eliminarRecurrenteProdcuto',val)
        },
        agregarProducto(adicional){
            this.productos.push(adicional.producto)
            this.pedidoSeleccionado = adicional.recurrente
        },
        agregarProducto2(adicional){
            this.$emit('agregarProducto', adicional ,'nieto')
            this.pedidoSeleccionado = adicional.recurrente
        },
        async agregarAcarritoTendero(res){
            if(!res){
                this.$refs.modalAnadir.toggle()
                return
            }
            try {
                let productos = []
                for (const p of this.productos){
                    productos.push({id_producto:p.id,cantidad:p.cantidad})
                }

                const {data} = await Almacen.agregar_lista_productos(productos)
                this.$refs.modalAnadir.toggle()
                this.notificacion('Éxito','Productos agregados al carrito de abastecimiento','success')

            } catch (e){
                this.error_catch(e)
            }
        },
        openModalRealizarPedido(){
            let productos = []
            for (const p of this.productos){
                productos.push({id_producto:p.id,cantidad:p.cantidad})
            }
            this.$refs.modalPedidoAlmacen.toggle(productos)
        }
    }
}
</script>
<style lang="scss" scoped>
    .panel{
        border: 1px solid #dee2e6;
        width: calc(100vw - 483px);
        height: calc( 100vh - 137px ) ;
    }
    .header-panel{
        padding: 12px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dee2e6;
        flex-wrap: wrap;
        &__btn{
            border:0;
            border-radius: 10px;
            width: 36px;
            height: 36px;
            cursor: pointer;
            box-shadow: 0px 3px 5px   rgba(0,0,0,.2);
            &--descuento{
                width: 159px;
            }
            &--position{
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }
        &__options{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }
    }
    .list-productos{
        height: calc(100% - 82px);
        &__cantidades{
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        &__layout{
            display: flex;
            flex-wrap: wrap;
            overflow: auto;
            height: calc(100% - 39px);
            align-content: baseline;
            // justify-content: space-between;
        }
    }
    .cardProducto-container{
        height: fit-content;
        width: 336px;
        margin: 0 10px;
    }

/*-*-*-*-*-*-*-* Media Queries -*-*-*-*-*-*-*/

    @media(max-width:1000px){
        .panel{
            width: calc(100vw - 401px) !important;
        }
        .list-productos{
            height: calc(100% - 92px) !important;
            &__layout{
                height: calc(100% - 54px);
            }
        }
    }
    @media(min-width:1000px) and (max-width : 1824px){
        .search{
            width: 400px !important;
        }
        .list-productos{
            &__layout{
                height: calc(100% - 38px);
            }
        }
    }
    @media (max-width:768px) {
        .list-productos{
            height: calc(100% - 128px) !important;
            &__layout{
                height: calc(100% - 55px);
            }
        }
    }

</style>
